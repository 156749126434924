<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-14 14:11:02
 * @LastEditTime: 2021-06-30 16:47:00
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="public-item">
    <!-- 一级菜单下面所拥有的二级菜单 -->
    <el-container>
      <el-aside width="228px">
        <ul class="left-mune">
          <li
            v-for="(item, index) in publicList"
            :key="index"
            :class="{ active: activeId == item.id }"
            @click="selected(item)"
          >
            {{ item.categoryTitle }}
          </li>
        </ul>
      </el-aside>

      <!-- 以及二级菜单所对应的页面 -->
      <el-main class="conten-box">
        <LogoTitle :msg="title"></LogoTitle>
        <RichArticle :html="detail.contentDetails"></RichArticle>
        <template v-if="list.length > 0">
          <LogoTitle msg="项目动态"></LogoTitle>
          <div class="list">
            <NewsCard
              source="Project"
              v-for="item in list"
              :info="item"
              :key="item.id"
              @tagClick="tagClick"
            ></NewsCard>
          </div>
          <div class="sljr-pagination">
            <SinglePagination
              :total="total"
              v-if="total <= pageSize"
            ></SinglePagination>
            <el-pagination
              :hide-on-single-page="isHide"
              :page-size="pageSize"
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </template>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  computed: {
    publicList () {
      return this.$store.state.publicWelfareList || []  // 取出公益项目
    }
  },
  data () {
    return {
      activeId: '',//当前选中的ID
      title: '标题',
      detail: {},
      currentPage: 1,//当前页数
      pageSize: 5,//每页显示条目个数
      total: 0,//总条目数
      isHide: true,//只有一页时是否隐藏
      list: '',
      tagsId: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      //获取当前id
      if (!this.$route.query.id) {
        this.activeId = this.publicList[0].id
      } else {
        this.activeId = this.$route.query.id
      }
      this.$router.push({ path: '/pubilcWelfare/publictItem', query: { id: this.activeId } })
      //获取详情
      this.getDetail();
      this.getList()
    },
    /**
     * @description: 项目动态列表
     * @param  {contentType: id,categoryType,pageNo,pageSize}
     * @return {*}
     */
    getList () {
      let params = {
        contentType: this.activeId,
        categoryType: 1,
        pageNo: this.currentPage,
        pageSize: this.pageSize
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
      })
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
      document.body.scrollIntoView();
    },
    tagClick (data) {
      this.tagsId = data;
      this.currentPage = 1;
      this.getFilterList();
    },
    //标签筛选
    getFilterList () {
      let params = {
        categoryId: this.activeId,
        categoryType: '1',
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        tagsId: this.tagsId
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
      })
    },
    /**
     * @description: 获取详情
     * @param  {*}
     * @return {*}
     */
    getDetail () {
      let params = {
        categoryId: this.activeId
      }
      this.$api.getCategoryDetail(params).then((res) => {
        this.detail = res.data.data
        this.title = this.detail.contentTag[0].tagName
        document.title = this.title;

      })
    },
    /**
     * @description: 切换左菜单
     * @return {*}
     * @param {*} item
     */
    selected (item) {
      this.$router.push({ path: '/pubilcWelfare/publictItem', query: { id: item.id } })
      this.activeId = item.id
      this.tagsId = '';
      this.currentPage = 1;
      this.getDetail();
      this.getList();
    }
  },
  watch: {
    publicList (n) {
      console.log('publicList', n)
      this.init();
    },
    $route (val) {
      console.log('$route.params.id', val.query.id)
      this.selected(val.query)
    }
  }
}
</script>

<style scoped lang='scss'>
.left-mune {
  li {
    width: 228px;
    height: 48px;
    background: #fbfbfb;
    line-height: 48px;
    text-align: center;
    margin-bottom: 2px;
    font-size: 16px;
    color: #666;
    cursor: default;
    &:hover {
      background: #ff2132;
      color: #fff;
    }
    &.active {
      background: $main-color;
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>